<template>
  <div class="home">

    <!--标题搜索-->
    <div class="module-head-search">
      <div class="moduletitle">成为Gfast开发者，共同分享插件市场</div>
      <div class="moduledesc">您提供的插件必须是基于Gfast框架开发的应用插件！</div>
      <div class="modulesearch">
        <input type="text"
               id="registerVal"
               v-model="query.pluginName"
               placeholder="填写你要搜索的插件">
        <div class="module-btn"
             @click="getList">搜索</div>
      </div>
    </div>
    <div class="clr"></div>
    <!--选项-->
    <div class="module-box">
      <div class="module-item">
        <ul>
          <li class="module-bott"> <span>功能：</span>
            <p>
              <a :class="['search-head', {active: query.pluginCateId == ''}]"
                 @click.prevent="searchList({pluginCateId : ''})">全部</a>
              <a v-for="item in plugincate"
                 :key="item.cateId"
                 @click.prevent="searchList({pluginCateId : item.cateId +''})"
                 :class="['search-head', {active: query.pluginCateId == item.cateId +''}]">{{item.cateName}}</a>
            </p>
          </li>
          <li> <span>价格：</span>
            <p>
              <a :class="['search-head', {active: query.priceType == ''}]"
                 @click.prevent="searchList({priceType : ''})">全部</a>
              <a :class="['search-head', {active: query.priceType == '1'}]"
                 @click.prevent="searchList({priceType : '1'})">免费</a>
              <a :class="['search-head', {active: query.priceType == '2'}]"
                 @click.prevent="searchList({priceType : '2'})">付费</a>
            </p>
            <label>
              <input type="checkbox"
                     name="isOfficial"
                     value="1"
                     @click="isOfficial">
              <font> 官方应用</font>
            </label>
          </li>
        </ul>
      </div>

      <div class="module-list">
        <ul>
          <li v-for="(item, index) in list"
              :key="index">
            <div class="mcard"
                 @click="jump(item)">
              <a><img :src="item.pluginThumb || imgUrl300x200" /></a>
              <p class="mtitle">{{item.pluginName}}</p>
              <p class="price">{{item.pluginPrice | fenToYuan}}</p>
              <P class="update">{{item.created_at}}</P>
              <P class="download">下载：{{item.downloadTimes || 0}}次</P>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <el-row :gutter="10"
            style="margin-bottom:20px;"
            type="flex"
            justify="center">
      <el-col :span="6">
        <el-pagination background
                       v-show="total > query.pageSize"
                       layout="prev, pager, next"
                       :page-size="query.pageSize"
                       :total="total">
        </el-pagination>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import mixinImgUrl from '@/utils/mixin/imgUrl.js'
import { list as listReq, getPlugincate } from '@/api/plugin'
export default {
  name: 'Home',
  mixins: [mixinImgUrl],
  data () {
    return {
      query: {
        pageSize: 1000,
        pageNum: 1,
        pluginCateId: '',
        priceType: '', // '' - 全部 '1' - 免费 '2' - 付费
        pluginName: ''
      },
      list: [],
      total: 0,
      plugincate: []
    }
  },
  created () {
    // console.log(this.$route)
    const searchKey = this.$route.query.searchKey
    if (searchKey && searchKey !== '') {
      this.query.pluginName = searchKey
    }
    this.getList()
    this.getPlugincate().then(data => {
      this.plugincate = data
    })
  },
  methods: {
    searchList (query) {
      Object.assign(this.query, query)
      this.getList()
    },
    isOfficial (e) {
      let isOfficial = ''
      if (e.target.checked) {
        isOfficial = '1'
      }
      this.searchList({ isOfficial: isOfficial })
    },
    jump (item) {
      this.$router.push({ path: '/showModule', query: { id: item.pluginId } })
    },
    getList () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      listReq(this.query).then(res => {
        const { code, data: { list, total }, msg } = res
        if (code !== 0) {
          this.$message.error(msg)
          return []
        }
        this.list = list
        this.total = total
        return list
      }).finally(() => {
        loading.close()
      })
    },
    getPlugincate () {
      return getPlugincate().then(res => {
        const { code, data, msg } = res
        if (code !== 0) {
          this.$message.error(msg)
          return []
        }
        return Array.isArray(data) ? data : []
      })
    }
  }

}
</script>

<style lang="less" scoped>
.active {
  color: #155bd4;
}

.search-head {
  cursor: pointer;
}

.mcard {
  cursor: pointer;
}
</style>
